"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.trainTaggedFilter = exports.trainSortMap = exports.trainIsStale = exports.roundTimestamp = exports.reduceTrainList = exports.reduceRadarList = exports.reduceEquipmentList = exports.projectPointBetween = exports.parseTimestamp = exports.isIE = exports.haversine = exports.hasMissingAvlAlert = exports.hasMissingAcsesAlert = exports.hasBadAvlAlert = exports.getTrainStateLabel = exports.getTrainListForTimestamp = exports.getPlaybackTimingFromSpeed = exports.getNextTrainLayerState = exports.getListForTimestamp = exports.getLastUpdatedText = exports.getDelayTextColor = exports.getDelayText = exports.getDelayStatus = exports.getDelayColor = exports.getDataForTimestamp = exports.getBoardingLocationText = exports.formatMoment = exports.filterTrainsByUserPreference = exports.convertSpeed = exports.clearCache = exports.checkIfBufferingDataNeeded = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _lodash = _interopRequireWildcard(require("lodash"));
var _rxjs = require("rxjs");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var getNextTrainLayerState = function getNextTrainLayerState(state) {
  switch (state) {
    case true:
    case 'all':
      return 'tagged';
    case 'tagged':
      return 'untagged';
    case 'untagged':
      return false;
    default:
      return 'all';
  }
};
exports.getNextTrainLayerState = getNextTrainLayerState;
var getTrainStateLabel = function getTrainStateLabel(state) {
  switch (state) {
    case true:
    case 'all':
      return 'All';
    case 'tagged':
      return 'Tagged';
    case 'untagged':
      return "Untagged";
    default:
      return false;
  }
};
exports.getTrainStateLabel = getTrainStateLabel;
var trainTaggedFilter = function trainTaggedFilter(train, filter) {
  switch (filter) {
    case "tagged":
      return train.trip_short_name;
    case "untagged":
      return !train.trip_short_name;
    default:
      return true;
  }
};
exports.trainTaggedFilter = trainTaggedFilter;
var filterTrainsByUserPreference = function filterTrainsByUserPreference(routeDivisions, trains, preferences) {
  var result = trains;
  if (preferences && preferences.divisionAffinity && preferences.divisionAffinity != "all") {
    result = trains.filter(function (train) {
      if (train.trip && train.trip.routeId) {
        var tripDivision = routeDivisions[train.trip.routeId];
        return tripDivision == preferences.divisionAffinity;
      }
      return true;
    });
  }
  return result;
};
exports.filterTrainsByUserPreference = filterTrainsByUserPreference;
var getPlaybackTimingFromSpeed = function getPlaybackTimingFromSpeed(speed) {
  var step = 60 * 1000;
  var interval = 300;
  var modifier = 1;
  if (speed >= 30) {
    modifier = 12;
  } else if (speed >= 10) {
    modifier = 3;
  } else if (speed >= 5) {
    modifier = 2;
  }
  step = step * modifier;
  interval = interval / (speed / modifier);
  var tick = step / interval;
  var leadTime = tick * 2000;
  return {
    step: step,
    tick: tick,
    interval: interval,
    leadTime: leadTime,
    modifier: modifier
  };
};
exports.getPlaybackTimingFromSpeed = getPlaybackTimingFromSpeed;
var checkIfBufferingDataNeeded = function checkIfBufferingDataNeeded(speed, leadTime, timestamp, targetTimestamp) {
  // if no max loaded, just return true
  if (!targetTimestamp) {
    return true;
  }
  var _getPlaybackTimingFro = getPlaybackTimingFromSpeed(speed),
    tick = _getPlaybackTimingFro.tick;
  return timestamp.clone().add(leadTime, 'ms').isSameOrAfter(targetTimestamp);
};
exports.checkIfBufferingDataNeeded = checkIfBufferingDataNeeded;
var getDelayColor = function getDelayColor(delay) {
  if (delay === null) {
    return "#9E9E9E";
  } else {
    var statusColor = "#13A89E";
    if (delay >= 4) {
      //we're delayed, lets figure out by how much
      if (delay < 10) {
        statusColor = "#FFDE16";
      } else if (delay < 20) {
        statusColor = "#FFA516";
      } else if (delay < 30) {
        statusColor = "#FF7216";
      } else if (delay < 40) {
        statusColor = "#ED1C24";
      } else {
        statusColor = "#000000";
      }
    }
    return statusColor;
  }
};
exports.getDelayColor = getDelayColor;
var getDelayStatus = function getDelayStatus(delay) {
  var status = "on-time";
  if (delay > 4) {
    //we're delayed, lets figure out by how much
    if (delay <= 10) {
      status = "late";
    } else if (delay <= 20) {
      status = "pretty-late";
    } else if (delay <= 30) {
      status = "very-late";
    } else if (delay <= 40) {
      status = "super-late";
    } else {
      status = "ultra-late";
    }
  }
  return status;
};
exports.getDelayStatus = getDelayStatus;
var getDelayText = function getDelayText(delay) {
  if (delay === null || delay === undefined) {
    return "N/A";
  }
  var text = "on time";
  if (delay > 4) {
    text = "".concat(delay, " min late");
  } else if (delay < 0) {
    text = "".concat(delay * -1, " min early");
  }
  return text;
};
exports.getDelayText = getDelayText;
var getDelayTextColor = function getDelayTextColor(delay) {
  if (delay <= 30 && delay > 4) {
    return '#000000';
  } else return '#FFFFFF';
};
exports.getDelayTextColor = getDelayTextColor;
var formatMoment = function formatMoment(timestamp) {
  return timestamp.utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]');
};
exports.formatMoment = formatMoment;
var parseTimestamp = function parseTimestamp(timestamp) {
  return _moment["default"].utc(timestamp, 'YYYY-MM-DD[T]HH:mm:ss[Z]');
};
exports.parseTimestamp = parseTimestamp;
var roundTimestamp = function roundTimestamp(timestamp, increment) {
  var remainder = (timestamp.minutes() * 60 * 1000 + timestamp.seconds() * 1000 + timestamp.milliseconds()) % increment;
  if (remainder < increment / 2) {
    timestamp.subtract(remainder, 'ms');
  } else {
    timestamp.add(increment - remainder, 'ms');
  }
  return timestamp;
};

// COMMON
exports.roundTimestamp = roundTimestamp;
var getListForTimestamp = function getListForTimestamp(cache, timestamp) {
  var trainMap = _lodash["default"].keys(cache)
  // Get only timestamps at or before the current timestamp
  .filter(function (trainTimestamp) {
    return (0, _moment["default"])(trainTimestamp).isSameOrBefore(timestamp);
  })
  // sort by date ascending, so most recent are last
  .sort()
  // map key back to full object
  .map(function (t) {
    return cache[t];
  })
  // merge together to form a single
  .reduce(function (acc, val) {
    return _lodash["default"].assign(acc, val);
  }, {});
  return _lodash["default"].values(trainMap);
};

// TRAINS
exports.getListForTimestamp = getListForTimestamp;
var reduceTrainList = function reduceTrainList(cache, trainList) {
  return trainList.reduce(function (aggregator, snapshot) {
    // append snapshot to cache
    aggregator[snapshot.timestamp] = snapshot.trains;
    return aggregator;
  }, cache);
};
exports.reduceTrainList = reduceTrainList;
var getTrainListForTimestamp = function getTrainListForTimestamp(cache, timestamp) {
  // Get closest snapshot to timestamp, but not after
  var bestTimestamp = _lodash["default"].chain(cache).keys()
  // Get only timestamps at or before the current timestamp
  .filter(function (snapshot) {
    return (0, _moment["default"])(snapshot).isSameOrBefore(timestamp);
  }).max().value();
  var bestSnapshot = cache[bestTimestamp];
  return bestSnapshot ? bestSnapshot : [];
};
exports.getTrainListForTimestamp = getTrainListForTimestamp;
var clearCache = function clearCache(cache, timestamp, lookback) {
  var cutoff = timestamp.clone().subtract(lookback, 'ms');
  return _lodash["default"].pickBy(cache, function (value, key) {
    return (0, _moment["default"])(key).isAfter(cutoff);
  });
};

// export const trainIsStale = (train) =>
//   train.timestamp && moment().diff(moment(train.timestamp), 'minutes', true) > 5
exports.clearCache = clearCache;
var trainIsStale = function trainIsStale(train) {
  return train.timestamp && Math.floor(Date.now() / 1000) - train.timestamp > 360;
};
exports.trainIsStale = trainIsStale;
var trainSortMap = {
  alert: function alert(train) {
    var _train$vehicle_status;
    var result = '';
    var isGhost = ((_train$vehicle_status = train.vehicle_status) === null || _train$vehicle_status === void 0 ? void 0 : _train$vehicle_status.toLowerCase()) === 'ghost';
    var isInferred = (train === null || train === void 0 ? void 0 : train.inferred_login) === true;
    var isDuplicate = train.double_id === true;
    var isCancelled = train.is_cancelled === true;
    var isStale = trainIsStale(train);
    var isMissingAcses = hasMissingAcsesAlert(train);
    var isMissingAvl = hasMissingAvlAlert(train);
    var isBadAvl = hasBadAvlAlert(train);
    var alertMap = {
      isGhost: 'a',
      inferredLogin: 'b',
      isMissingAcses: 'f',
      isMissingAvl: 'j',
      isBadAvl: 'm',
      isDuplicate: 'w',
      isCanceled: 'x',
      isStale: 'z'
    };
    var allAlerts = [];
    if (trainIsStale(train)) {
      allAlerts.push('isStale');
    }
    if (train.alerts) {
      allAlerts = allAlerts.concat(train.alerts);
    }
    if (isGhost) {
      result += 'a';
    } else {
      result += 'y';
    }
    if (isInferred) {
      result += 'b';
    } else {
      result += 'y';
    }
    if (isDuplicate) {
      result += 'w';
    } else {
      result += 'y';
    }
    if (isCancelled) {
      result += 'x';
    } else {
      result += 'y';
    }
    if (isStale) {
      result += 'z';
    } else {
      result += 'y';
    }
    if (isMissingAcses) {
      result += 'f';
    } else {
      result += 'y';
    }
    if (isMissingAvl) {
      result += 'j';
    } else {
      result += 'y';
    }
    if (isBadAvl) {
      result += 'f';
    } else {
      result += 'y';
    }

    // Object.keys(alertMap).forEach((key) => {
    //   if (allAlerts.includes(key)) {
    //     result += alertMap[key]
    //   } else {
    //     result += 'y' 
    //   }
    // })

    if (train.trip_short_name) {
      result += ':' + train.trip_short_name;
    }
    if (train.vehicle) {
      result += ':' + train.vehicle;
    }
    return result;
  },
  // alert: train => (train.alerts ? train.alerts.length * -1 : 0) - (trainIsStale(train) ? 1 : 0),
  train: function train(_train) {
    var result = 999;
    if (_train.trip_short_name) {
      result = 1;
    }
    return result;
  },
  vehicle: function vehicle(train) {
    return train.vehicle && train.vehicle;
  },
  destination: function destination(train) {
    var result = 'ZZZZZZZZZ';
    if (train.headsign && train.headsign) {
      result = train.headsign;
    } else if (train.alerts && train.login_status.toLowerCase() === 'ghost' && train.schedule) {
      var destinationStop = train.headsign;
      if (destinationStop && destinationStop.name) {
        result = train.headsign;
      }
    }
    return result;
  },
  route: function route(train) {
    return train.trip && train.trip.routeId;
  },
  nextStop: function nextStop(train) {
    var boardingLocationText = getBoardingLocationText(train);
    if (!boardingLocationText) {
      // Ensure trains with no boarding location information appear
      // last/first depending on sort direction
      return "ZZZ";
    }
    return boardingLocationText;
  },
  track: function track(train) {
    return train.track_posted !== train.track_scheduled ? train.track_posted : train.track_scheduled;
  },
  delay: function delay(train) {
    var result = (train.delay ? train.delay : 0) * -1;
    if (result === 0 && train.delay !== 0) {
      // Ensure trains with no delay data appear grouped together
      result = 999;
    }
    return result;
  },
  speed: function speed(train) {
    return train.speed ? train.speed : 0;
  }
};

// Convert meters per second to miles per hour
exports.trainSortMap = trainSortMap;
var convertSpeed = function convertSpeed(speed) {
  return Math.round(speed * 2.23694);
};

// EQUIPMENT
exports.convertSpeed = convertSpeed;
var reduceEquipmentList = function reduceEquipmentList(cache, list) {
  return list.reduce(function (aggregator, item) {
    // Group by timestamp...
    var itemTimestamp = parseTimestamp(item.timestamp).toISOString();
    if (!aggregator[itemTimestamp]) {
      aggregator[itemTimestamp] = {};
    }
    // ..and subgroup by train
    aggregator[itemTimestamp][item.equipment.equipmentId] = item;
    return aggregator;
  }, cache);
};
exports.reduceEquipmentList = reduceEquipmentList;
var reduceRadarList = function reduceRadarList(cache, list) {
  return _lodash["default"].assign(cache, _lodash["default"].keyBy(list, 'fetchedAt'));
};
exports.reduceRadarList = reduceRadarList;
var getDataForTimestamp = function getDataForTimestamp(cache, timestamp) {
  var key = _lodash["default"].last(_lodash["default"].keys(cache).sort().filter(function (key) {
    return key < timestamp.toISOString();
  }));
  return cache[key];
};
exports.getDataForTimestamp = getDataForTimestamp;
var haversine = function haversine(start, end, options) {
  // convert to radians
  var toRad = function toRad(num) {
    return num * Math.PI / 180;
  };
  //get distance
  var km = 6371;
  var mile = 3960;
  options = options || {};
  var R = options.unit === "mile" ? mile : km;
  var dLat = toRad(end.latLong[0] - start.latLong[0]);
  var dLon = toRad(end.latLong[1] - start.latLong[1]);
  var lat1 = toRad(start.latLong[0]);
  var lat2 = toRad(end.latLong[1]);
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  if (options.threshold) {
    return options.threshold > R * c;
  } else {
    return R * c;
  }
};
exports.haversine = haversine;
var projectPointBetween = function projectPointBetween(linePoint1, linePoint2, toProject) {
  var toLatLongObj = function toLatLongObj(obj) {
    return {
      lat: obj.latLong[0],
      "long": obj.latLong[1]
    };
  };
  var line1 = toLatLongObj(linePoint1);
  var line2 = toLatLongObj(linePoint2);
  var point = toLatLongObj(toProject);
  var m = (line2["long"] - line1["long"]) / (line2.lat - line1.lat);
  var b = line1["long"] - m * line1.lat;
  var lat = (m * point["long"] + point.lat - m * b) / (m * m + 1);
  var _long = (m * m * point["long"] + m * point.lat + b) / (m * m + 1);

  //now check to make sure this point is between the endpoints
  if (Math.abs(m) > 1) {
    //line is more N/S than E/W, compare longs
    if (m > 0 ? _long >= line1["long"] && _long <= line2["long"] : _long >= line2["long"] && _long <= line1["long"]) {
      //ok, we're on the line, just return
      return [lat, _long];
    }
  } else {
    //line is more E/W than N/S, compare lats
    if (m > 0 ? lat >= line1.lat && lat <= line2.lat : lat >= line2.lat && lat <= line1.lat) {
      //ok, we're on the line, just return
      return [lat, _long];
    }
  }
  return null;
};
exports.projectPointBetween = projectPointBetween;
var isIE = function isIE() {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf('MSIE '); // IE 10 or older
  var trident = ua.indexOf('Trident/'); //IE 11

  return msie > 0 || trident > 0;
};
exports.isIE = isIE;
var getBoardingLocationText = function getBoardingLocationText(train) {
  var boardingLocationText = '';
  if (train.currentStop) {
    boardingLocationText = train.currentStop.stopName;
    if (train.currentStop.boardingLocation && train.currentStop.boardingLocation.platformCode) {
      boardingLocationText += " (Trk. ".concat(train.currentStop.boardingLocation.platformCode, ")");
    }
  }

  //denny
  if (!boardingLocationText && train.next_stop) {
    return train.next_stop;
  }
  // over

  return boardingLocationText;
};
exports.getBoardingLocationText = getBoardingLocationText;
var getLastUpdatedText = function getLastUpdatedText(train) {
  var timeResult = 'Unknown';
  var viaResult = 'Unknown';
  // console.log('aint no dot position')

  if (train && train.position && train.position.updateTime) {
    var updateTimeMoment = (0, _moment["default"])(train.position.updateTime);
    if (updateTimeMoment && updateTimeMoment.isValid()) {
      var nowMoment = (0, _moment["default"])();
      var timeDiff = nowMoment.diff(updateTimeMoment);
      var timeDuration = _moment["default"].duration(timeDiff);
      if (timeDuration.as('seconds') < 60) {
        timeResult = 'just now';
      } else if (timeDuration.as('days') > 24) {
        timeResult = "".concat(Math.floor(timeDuration.as('days')), " days ago");
      } else {
        timeResult = updateTimeMoment.fromNow();
      }
      if (train.position.source) {
        viaResult = train.position.source;
      }
    }
  }
  return {
    time: timeResult,
    via: viaResult
  };
};
exports.getLastUpdatedText = getLastUpdatedText;
var hasMissingAcsesAlert = function hasMissingAcsesAlert(train) {
  return (train === null || train === void 0 ? void 0 : train.vehicle_status) === 'REVENUE' && (train === null || train === void 0 ? void 0 : train.acses_mode) !== "1";
};
exports.hasMissingAcsesAlert = hasMissingAcsesAlert;
var hasMissingAvlAlert = function hasMissingAvlAlert(train) {
  return (train === null || train === void 0 ? void 0 : train.vehicle_status) === 'REVENUE' && (train === null || train === void 0 ? void 0 : train.avl_data) === "Offline";
};
exports.hasMissingAvlAlert = hasMissingAvlAlert;
var hasBadAvlAlert = function hasBadAvlAlert(train) {
  return (train === null || train === void 0 ? void 0 : train.vehicle_status) === 'REVENUE' && (train === null || train === void 0 ? void 0 : train.avl_data) === "False";
};
exports.hasBadAvlAlert = hasBadAvlAlert;