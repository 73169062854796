import React from 'react';

const UNTAGGED_COLOR = '#9E9E9E';

const getDelayColor = function (delay) {
  if (delay === null) {
    return '#9E9E9E';
  }
  let statusColor = '#13A89E';

  if (delay > 4) {
    // we're delayed, lets figure out by how much
    if (delay <= 10) {
      statusColor = '#FFDE16';
    } else if (delay <= 20) {
      statusColor = '#FFA516';
    } else if (delay <= 30) {
      statusColor = '#FF7216';
    } else if (delay <= 40) {
      statusColor = '#ED1C24';
    } else {
      statusColor = '#000000';
    }
  }

  return statusColor;
};

function MobileLegend() {
  return (
    <div className="mobile-legend">
      <div className="legend-section">Delay</div>
      <div className="mobile-legend-entry">
        <span style={{ backgroundColor: getDelayColor(0) }} className="mobile-legend-item square" />
        <span className="legend-label">&lt; 4 minutes</span>
      </div>
      <div className="mobile-legend-entry">
        <span style={{ backgroundColor: getDelayColor(5) }} className="mobile-legend-item square" />
        <span className="legend-label">4 to 9 minutes</span>
      </div>
      <div className="mobile-legend-entry">
        <span style={{ backgroundColor: getDelayColor(15) }} className="mobile-legend-item square" />
        <span className="legend-label">10 to 19 minutes</span>
      </div>
      <div className="mobile-legend-entry">
        <span style={{ backgroundColor: getDelayColor(25) }} className="mobile-legend-item square" />
        <span className="legend-label">20 to 29 minutes</span>
      </div>
      <div className="mobile-legend-entry">
        <span style={{ backgroundColor: getDelayColor(35) }} className="mobile-legend-item square" />
        <span className="legend-label">30 to 39 minutes</span>
      </div>
      <div className="mobile-legend-entry">
        <span style={{ backgroundColor: getDelayColor(45) }} className="mobile-legend-item square" />
        <span className="legend-label">&gt; 40 minutes</span>
      </div>
      <div className="legend-section">Type</div>
      <div className="mobile-legend-entry">
        <span style={{ backgroundColor: UNTAGGED_COLOR }} className="mobile-legend-item square" />
        <span className="legend-label">Untagged</span>
      </div>
    </div>
  );
}

export default MobileLegend;
